.withdrawal-requests-filter-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;

  &__wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2px 0.5rem;

    &_isDateWrapper {
      gap: 2px 3px;
    }

    &:first-child {
      margin: 0 0 0.4375rem 0;
    }
  }
  &__title {
    margin: 1.0625rem 0 0.4375rem 0;
    font-weight: 700;
    font-size: 0.75rem;
    color: #888888;
    text-transform: capitalize;
  }

  &__item-label {
    font-weight: 500;
    font-size: 0.75rem;
    color: #ffffff;
    white-space: nowrap;
  }

  &__item-date {
    min-width: 0;
    align-self: flex-end;
    width: 100%;
    height: 2.8125rem;
    display: flex;
    flex-direction: column;
  }

  &__item-selector {
    min-width: 0;
    align-self: flex-end;
    height: 2.5rem;
    width: 100%;
  }
  &__selector-item {
    display: flex;
    align-items: center;
  }
  &__selector-item-icon {
    width: 1.5rem;
    margin: 0 0.3125rem 0 -0.625rem;
  }
  &__selector-item-text {
    font-weight: 600;
    font-size: 0.875rem;
    color: #ffffff;
  }

  &__item-checkbox {
    min-width: 0;
    align-self: center;
    width: 100%;
    display: flex;
  }
  &__checkbox-container {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:not(:last-child) {
      margin: 0 0.9375rem 0 0;
    }
  }
  &__checkbox {
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0.5625rem 0 0;
  }
  &__checkbox-text {
    font-weight: 600;
    font-size: 0.875rem;
    color: #ffffff;
    white-space: nowrap;
  }

  &__button {
    min-width: 0;
    align-self: flex-end;
    width: 100%;
    height: 2.8125rem;
    margin: 0.438rem 0 0 0;
  }
  &__button-text {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    color: #ffffff;
  }
}
