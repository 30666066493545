.transaction-history {
  padding-bottom: 2rem;
  padding: 0.5rem 1rem;
  color: #fff;
  &__filter {
    &-item {
      padding: 1rem 0.5rem 0.5rem 0.5rem;
    }
    &-title {
      margin-bottom: 0.5rem;
      font-weight: bold;
      font-size: 0.875rem;
    }
  }

  &__input {
    border: 0.0625rem solid rgba(255, 255, 255, 0.4);
    display: flex;
    align-items: center;
    position: relative;
  }

  &__calendar {
    position: absolute;
    right: 0.5rem;
    width: 1.7143rem;
    height: 1.7143rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &-svg {
      width: 1rem;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }

  .react-datepicker__input-container {
    height: 100%;
    input {
      width: 100%;
      height: 2.625rem;
      border: 0.0625rem solid rgba(255, 255, 255, 0.4);
      outline: none;
      background: none;
      color: #fff;
      border: none;
      padding: 0;
      padding-left: 1.5rem;
      box-sizing: border-box;
    }
  }

  &__search-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0e5f31;
    font-weight: 700;
    height: 2.625rem;
    width: 64%;
    margin: 0.5rem auto;
  }

  &__search-button-icon {
    position: absolute;
    top: calc(50% - 0.78rem);
    right: 0.5rem;
    width: 1.5714rem;
    height: 1.5714rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    &-svg {
      width: 1.1429rem;
      height: 1.1429rem;
      * {
        fill: #fff;
      }
    }
  }

  &__search-block {
    padding: 0.7rem;
    position: relative;
    margin-bottom: 1rem;
  }

  &__search-icon {
    position: absolute;
    left: 1.2rem;
    top: calc(50% - 0.5rem);

    &-svg {
      width: 1rem;
      * {
        fill: #fff;
      }
    }
  }

  &__users-header {
    background: #333333;
    border-radius: 0.625rem;
    height: 2.5625rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    padding: 0 1rem;
    box-sizing: border-box;
  }
  &__result-wrapper {
    padding: 0 0.1875rem;
  }

  &__user-header-item {
    font-size: 0.7143rem;
    width: 100%;
    &_type {
      &_center {
        text-align: center;
      }
      &_right {
        text-align: right;
        max-width: 15%;
      }
    }
  }
}
