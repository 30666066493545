.menu-item {
  width: 90%;
  height: 2.8rem;
  margin-bottom: 0.0625rem;
  border: 0.0625rem solid rgba(255, 255, 255, 0.4);
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 1rem;

  &__image {
    display: block;
    width: 1rem;
    &_away {
      width: 0.5rem;
    }
    &_isOpen {
      transform: scale(-1);
    }
  }
  &__element-list {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__element {
    width: 80%;
    height: 2.5625rem;
    font-size: 0.875rem;
    font-weight: bold;
    border: 0.0625rem solid rgba(255, 255, 255, 0.4);
    background: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1rem;
    margin-bottom: 0.25rem;
  }
}
