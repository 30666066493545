.modal-mobile {
  width: 100%;
  height: 100%;
  position: fixed;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index: 1004;
  top: 0;
  backdrop-filter: blur(5px);

  &__content-container {
    width: 90%;
    max-width: 30rem;
    overflow-y: auto;
    max-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
