.withdrawal-requests-item-mobile {
  &__item {
    background-color: #333333;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0.313rem;

    &-block {
      display: flex;
      flex-direction: column;
      padding: 0.625rem 1.25rem;
    }

    &-label {
      color: rgba(255, 255, 255, 0.4);
      font-size: 0.625rem;
      font-weight: 700;
    }

    &-amount {
      color: #ffffff;
      font-size: 0.75rem;
      font-weight: 700;

      &_color {
        &_yellow {
          font-size: 0.875rem;
          color: #ffe71f;
        }
      }
    }
  }
  &__item-button {
    display: flex;
    justify-content: flex-end;
    margin-right: 0.875rem;

    &-block {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-title {
      font-weight: 700;
      font-size: 0.875rem;
      text-align: center;
      text-transform: uppercase;
      color: #0e5f31;
      margin-right: 1.063rem;
    }
    &-arrow {
      width: 0.5rem;
      height: 0.875rem;
      display: flex;
    }
  }
}
