.change-pas {
  width: 100%;
  box-sizing: border-box;
  padding: 0rem 2rem;
  padding-top: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__button {
    margin-top: 5rem;
    width: 80%;
    height: 3rem;
    background-color: #0e5f31;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: 700;
    color: #fff;
    border: none;
    cursor: pointer;
  }
}
