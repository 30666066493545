.notification {
  display: block;
  width: 100%;
  min-height: 1rem;
  position: relative;
  z-index: 100;
  margin-top: 1rem;
  color: #fff;
  padding: 0.9375rem 0;
  font-size: 1.4rem;
  animation: 0.7s ease init;
  background: #333333;
  box-shadow: 0 0.3125rem 0.625rem rgba(46, 66, 98, 0.05);
  border-radius: 0.625rem;
  border-radius: 0.625rem;

  &_deleting {
    animation: 0.7s ease delete;
  }

  &__svg {
    width: 1.2rem;
    display: flex;
    align-items: center;
    margin: 0 0.6875rem;

    &-svg {
      width: 1.2rem;
      height: 1.2rem;
    }

    &_cross {
      width: 0.8125rem;
      cursor: pointer;

      &-svg {
        width: 0.8125rem;
        * {
          fill: #fff;
        }
      }
    }
  }

  &__text {
    flex-grow: 2;
    height: 100%;
    font-weight: 700;
    font-size: 0.875rem;
    text-align: left;
    word-break: break-word;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__left {
    align-self: flex-start;
  }
  &__right {
    align-self: flex-start;
  }

  @keyframes init {
    0% {
      opacity: 0;
      transform: translateX(10rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes delete {
    0% {
      opacity: 1;
    }
    70% {
      opacity: 0.5;
      transform: translateX(120%);
    }
    100% {
      opacity: 0;
      transform: translateX(200%);
    }
  }
}
