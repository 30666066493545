.withdrawal-requests-card {
  min-width: 21.125rem;
  padding: 0.625rem 1.125rem 1.875rem 1.125rem;
  background: #333333;

  &__header {
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0.625rem 0;
  }
  &__header-title {
    font-weight: 700;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  &__header-cross {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 0 0.625rem 0;
  }

  &__item-image {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column: 1 / 3;
  }


  &__item {
    display: flex;
    flex-direction: column;
    padding: 0.625rem 0;
  }

  &__item-title {
    margin: 0 0 3px 0;
    font-weight: 700;
    font-size: 0.625rem;
    color: #888888;
  }

  &__item-button {
    margin-right: 0.625rem;
    background: transparent;
    border: none;
    padding: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  &__icon {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
  }

  &__item-value {
    font-weight: 700;
    font-size: 0.75rem;
    color: #ffffff;
    &_color {
      &_yellow {
        color: #ffe71f;
      }
    }
  }

  &__field {
    height: 2.8125rem;
    margin-bottom: 0.625rem;
    background: #000000;
  }

  &__button {
    height: 2.125rem;
    text-transform: uppercase;
    &:not(:last-child) {
      margin-bottom: 0.625rem;
    }

    &-text {
      text-transform: uppercase;
    }
  }
}
