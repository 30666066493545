.total {
  padding: 0.5rem 1rem;
  &__title {
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 1rem;
    justify-content: center;
  }
  &__input-block {
    padding: 0.5rem;
    margin-top: 0.4rem;
    box-sizing: border-box;
    & input {
      border: 0.0625rem solid rgba(255, 255, 255, 0.4);
    }
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: 0.7em 1em;
  }
  &__button {
    position: relative;
    display: flex;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #0e5f31;
    height: 2.625rem;
    width: 64%;
    margin: 0.5rem auto;
    cursor: pointer;
    &_isLoading {
      cursor: not-allowed;
    }
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
  &__result-header {
    background: #333333;
    border-radius: 0.625rem;
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.7rem;
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 1.25rem;
  }
  &__result {
    padding: 0 0.4rem;
    box-sizing: border-box;
  }
  &__row {
    height: 2.5625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.7rem;
    font-size: 0.8rem;
    font-weight: 400;
    background: #1d1e1e;
    border-radius: 0.625rem;
    margin-bottom: 0.5rem;
  }
  &__td {
    width: 20%;
    &_type_left {
      width: 40%;
    }
  }
}
