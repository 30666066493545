.button-mobile {
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.75rem;
  border: none;
  outline: none;
  user-select: none;
  font-weight: 700;
  font-size: 0.875rem;
  color: white;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  white-space: nowrap;

  &_borderRadius {
    &_4 {
      border-radius: 0.3125rem;
    }
  }
  &:active {
    transform: translateY(2px);
  }

  &_color {
    &_default {
      border: 1px solid #0e5f31;
      background: #0e5f31;
    }
    &_hollow {
      background: transparent;
      border: 1px solid #147b45;
      color: #147b45;
    }
  }

  &_disabled {
    filter: grayscale(0.5);
    cursor: not-allowed;
    box-shadow: none;
  }
  &_disabled#{&}:active {
    transform: translateY(0px);
  }
}
