.image-preview {
  min-width: 21.125rem;
  padding: 0.625rem 1.125rem 1.875rem 1.125rem;
  background: #333333;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &__close {
    background: none;
    cursor: pointer;
    border: none;
    padding: 0;
    height: 2.5rem;
  }

  &__cross {
    display: flex;
    width: 1.25rem;
    height: 1.25rem;
  }

  &__image {
    margin-top: 1rem;
    width: 100%;
    height: auto;
  }
}