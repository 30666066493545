.input-date-mobile {
  user-select: none;
  cursor: pointer;
  height: 100%;

  & .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }
  & .react-datepicker__input-container {
    width: 100%;
    height: 100%;
  }
  & .react-datepicker__triangle {
    display: none;
  }

  &__custom-date-input {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.625rem;
    border: 1px solid rgba(255, 255, 255, 0.4);

    &-calendar {
      width: 1.5rem;
      min-width: 1.5rem;
      height: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0.3125rem 0 0;
    }
    &-value {
      margin-right: auto;
      font-weight: 400;
      font-size: 0.875rem;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #ffffff;
    }
    &-arrow {
      width: 1.875rem;
      min-width: 1.875rem;
      height: 1.875rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
