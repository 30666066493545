.select {
  user-select: none;
  touch-action: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  height: 2.5rem;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  outline: 0;
  color: #fff;
  background: none;
  &__item-selected {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.5rem;
  }
  &_color_default &__item-selected {
    padding: 0 0.625rem 0 1.25rem;
  }
  &_color_default &__item-selected:hover {
    background: #00cc68;
  }
  &_color_default#{&}_open &__item-selected {
    border-bottom: 1px solid transparent;
  }

  &__item-value-selected {
    font-weight: 600;
    font-size: 0.875rem;
    color: white;
    text-transform: capitalize;
  }
  &_color_default &__item-selected:hover &__item-value-selected {
    color: white;
  }

  &__item-arrow-selected {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &_open &__item-arrow-selected {
    transform: scaleY(-1);
  }
  &_color_default &__item-selected:hover &__item-arrow-selected * {
    fill: white;
  }

  &__items {
    width: 100%;
    max-height: 21rem;
    z-index: 2;
    position: absolute;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0.1875rem;
      height: 90%;
      scroll-margin-right: 0.5rem;
    }
    &::-webkit-scrollbar-thumb {
      width: 0.1875rem;
      background: gray;
      cursor: pointer;
    }
  }
  &_color_default &__items {
    top: 100%;
    background: #202020;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background-clip: content-box;
  }

  &__item {
    cursor: pointer;
    width: 100%;
    height: 2.5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.625rem 0 1.25rem;
    background-color: transparent;
    font-weight: 600;
    font-size: 0.875rem;
    color: white;
    text-transform: capitalize;

    &:not(:last-child) {
      border: 1px solid rgba(255, 255, 255, 0.4);
    }
  }
  &_color_default &__item:hover {
    background: #00cc68;
  }
}
