.top-up-requests {
  &__filters {
    padding: 0.625rem;
  }

  &__paginator {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.875rem 0;
  }
}
