.transaction-item {
  background: #1d1e1e;
  border-radius: 0.625rem;
  height: 2.5625rem;
  margin-top: 0.3125rem;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 1rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  &__column-amount {
    color: #147b45;
  }
  &__column,
  &__column-amount {
    font-size: 0.7857rem;
    width: 100%;
    &_type {
      &_center {
        text-align: center;
      }
      &_right {
        text-align: right;
      }
    }
    &_colored {
      color: #ff0000;
    }
    &_type_right {
      max-width: 15%;
    }
  }
}
