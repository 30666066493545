.locale {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    background: #0e5f31;
    width: 90%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
    margin-bottom: 8px;
  }
  &__item {
    width: 80%;
    height: 2.8rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 0.7rem;
    margin-bottom: 0.25rem;
    background: none;
    border: 0.0625rem solid rgba(255, 255, 255, 0.4);
    &_active {
      color: #fff;
      background-color: #0e5f31;
      border: none;
    }
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: 0.7em 1em;
    width: 65%;
  }
  &__button {
    width: 100%;
    height: 2.8rem;
    background-color: #0e5f31;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: 700;
    cursor: pointer;
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
}
