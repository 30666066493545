.input {
  user-select: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #333333;
  height: 2.5rem;
  box-sizing: border-box;

  &__native {
    width: 100%;
    height: 2.5rem;
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    color: #fff;
    background: none;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border: none;
  }

  &_color {
    &_white {
      border: 1px solid rgba(255, 255, 255, 0.4);
    }
  }
  &::-webkit-calendar-picker-indicator {
    filter: invert(27%) sepia(27%) saturate(1263%) hue-rotate(93deg)
      brightness(96%) contrast(92%);
  }

  &__measure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 1.25rem 0 1.25rem;
    font-weight: 400;
    font-size: 1rem;
    text-align: right;
    color: #ffffff;
  }
}
