.paginator {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  user-select: none;

  &__selected-item {
    width: 5.688rem;
    cursor: pointer;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3125rem;
    background: #333333;
  }

  &__selected-item-value {
    height: 100%;
    width: 5.688rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.3125rem 0 0;
    font-weight: 900;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #ffffff;
    text-align: center;
  }
  &__selected-item-arrow-wrapper {
    padding: 0.719rem 0.563rem;
    border: 1px solid #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__selected-item-arrow {
    width: 0.75rem;
    height: 0.438rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &_open &__selected-item-arrow {
    transform: scaleY(-1);
  }

  &__items {
    width: 100%;
    max-height: 9rem;
    z-index: 2;
    position: absolute;
    top: 100%;
    overflow: auto;
    border-top: none;
    background-color: #333333;

    &::-webkit-scrollbar {
      width: 0.1875rem;
      height: 90%;
      scroll-margin-right: 0.5rem;
    }
    &::-webkit-scrollbar-thumb {
      cursor: pointer;
      width: 0.1875rem;
      background: #019b43;
    }
  }
  &__item {
    width: 5.688rem;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  &__item-value {
    min-height: 1.875rem;
    width: 5.688rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #ffffff;
  }
}
