.subheader {
  width: 100%;
  height: 2.8rem;
  min-height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFF;
  box-sizing: border-box;
  padding: 0 .7rem;
  margin-bottom: 1px;
  font-weight: bold;
  &__home {
    width: 1.5rem;
    height: 1.5rem;
    * {
      fill: #FFF;
    }
  }
  &__image {
    width: 100%;
  }
}